/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Archipelago Yachts Receives Funding to Develop Methanol-Powered Leisure Vessel"), "\n", React.createElement(_components.p, null, "Archipelago Yachts, a UK-based yacht builder, has been awarded funding from the UK Department for Transport (DfT) to develop a methanol-powered leisure vessel. The objective of this project is to offer a sustainable alternative to traditional diesel-powered yachts and contribute to reducing carbon emissions in the maritime industry."), "\n", React.createElement(_components.h2, null, "Advancing Sustainable Practices in the Maritime Industry"), "\n", React.createElement(_components.p, null, "Archipelago Yachts aims to create a methanol demonstrator vessel powered by clean bio-methanol. The funding will also support the development of a larger vessel. This initiative is part of the UK's efforts to decarbonize the domestic maritime sector and promote sustainable practices in the industry."), "\n", React.createElement(_components.h2, null, "A Cleaner Fuel Source for Leisure Vessels"), "\n", React.createElement(_components.p, null, "The methanol-powered leisure vessel will be a prototype that runs on a methanol fuel cell system. This system provides a more sustainable and environmentally friendly alternative to traditional diesel engines. The project aims to demonstrate the feasibility and benefits of using methanol as a fuel source for leisure vessels."), "\n", React.createElement(_components.p, null, "Source:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.superyachttimes.com/yacht-news/archipelago-yachts-47-dft-funding"
  }, "Superyacht Times")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.marinelog.com/shipbuilding/engines-fuel/design-completion-for-world-first-methanol-powered-leisure-vessel-announced/"
  }, "Marine Log")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
